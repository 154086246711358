@import "./constants";
@import url('https://fonts.googleapis.com/css?family=Amiri');
@import url('https://fonts.googleapis.com/css?family=Raleway:100,100i,300,300i,400,400i,600,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,300,300i,400,400i,700,700i,900,900i');

body {
  font-family: 'Raleway', sans-serif;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.muted {
  color: #939aa0;
}

.min-vh-100 {
  min-height: 100vh;
}

.toast-container {
  .Toastify__toast {
    background-color: black;
    color: white;
  }
  .Toastify__close-button {
    color: white;
    opacity: 1;
  }
}

.pagination {
  flex-wrap: wrap;

  .page-item {
    margin: 3px;
    color: black;
    //font-weight: bold;
    font-size: 0.9em;

    .page-link, .page-link:visited, .page-link:hover, .page-link:focus {
      color: #555;
      border-radius: 5px;
      border: 0;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      min-width: 45px;
      text-align: center;
      font-size: 0.9em;
      font-weight: 600;
      transition: all 300ms ease;
    }
    .page-link:hover {
      background-color: #fff;
      box-shadow: 0 8px 16px rgba(0,0,0,0.1);
    }

    &.active {
      .page-link {
        background-color: #007bff;
        color: white;
      }
    }
  }
}

.material-icons {
  line-height: unset;
}

.input-group-append .btn, .input-group-prepend .btn {
  z-index: 0 !important;
}

.order-dashboard-table {

  tr {
    width: 100%;
  }

  th, td {
    border-top: none !important;
    font-size: 0.9em;
  }
}

.react-datepicker-wrapper {
  display: block !important;

  div {
    display: block;
  }
}
